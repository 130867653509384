import { defineComponent } from "vue";

import Hero from "@/components/hero/index.vue";
import SummitLink from "@/components/summit-link/index.vue";
import heroBg from "../../assets/images/about-us-bg.png";

export default defineComponent({
  name: "Business-Related Regulations",
  components: { SummitLink, Hero },
  setup() {
    const heroSlide = [
      {
        title: "Summit Report",
        image: heroBg,
      },
    ];

    return {
      heroSlide,
    };
  },
  data() {
    return {
      
      filesrcs: [
        { title: 'Nasarawa State Small Claims Court Practice Direction', src: 'https://api.nasida.na.gov.ng/downloads/Nasarawa-State-Small-Claims-Court-Practice-Direction', time:'Dec 30, 2023' },
        { title: 'Judgment by Chief Magistrate Court Upper Lafia', src: 'https://api.nasida.na.gov.ng/downloads/Judgment-by-Chief-Magistrate-Court-Upper-Lafia', time:'Dec 30, 2023' },
        { title: 'Judgment by Chief Magistrate Court 3 Lafia', src: 'https://api.nasida.na.gov.ng/downloads/Judgment-by-Chief-Magistrate-Court-3-Lafia', time:'Dec 30, 2023' },
        { title: 'SMALL CLAIMS COURT CREATION WARRANT', src: 'https://api.nasida.na.gov.ng/downloads/SMALL-CLAIMS-COURT-CREATION-WARRANT', time:'Dec 30, 2023' },
        // buseness enabling
        { title: 'PPP Circular', src: 'https://api.nasida.na.gov.ng/downloads/PPP-Circular', time:'Dec 30, 2023' },
        // 29/12/2023
        { title: 'EXECUTIVE ORDER ADOPTING FRILIA', src: 'https://api.nasida.na.gov.ng/downloads/EXECUTIVE-ORDER-ADOPTING-FRILIA', time:'Dec 30, 2023' },
        { title: 'NASIDA LAW', src: 'https://api.nasida.na.gov.ng/downloads/NASIDA-LAW', time:'Dec 29, 2023' },
        { title: 'NGREM CIRCULAR', src: 'https://api.nasida.na.gov.ng/downloads/NGREM-CIRCULAR', time:'Dec 29, 2023' },
        { title: 'NGREM Executive order', src: 'https://api.nasida.na.gov.ng/downloads/NGREM-Executive-order', time:'Dec 29, 2023' },
        { title: 'PPP REGULATIONS', src: 'https://api.nasida.na.gov.ng/downloads/PPP-REGULATIONS', time:'Dec 29, 2023' },
        { title: 'FRILIA CIRCULAR', src: 'https://api.nasida.na.gov.ng/downloads/FRILIA-CIRCULAR', time:'Dec 29, 2023' },
        { title: 'NASARAWA STATE RIGHT OF WAY REGULATION 2023', src: 'https://api.nasida.na.gov.ng/downloads/NASARAWA-STATE-RIGHT-OF-WAY-REGULATION-2023', time:'Dec 29, 2023' },
      ],
    }
  }, 

  methods: {
    goto(url) {
      window.open(url);
    },
  },


});
